
.chat-start {
    justify-content: flex-start;
}

.chat-bubble {
    padding: 8px 12px;
    border-radius: 8px;
    display: inline-block;
    margin: 5px;
    word-wrap: break-word;
}

.chat-bubble-info {
    background-color: #d1ecf1;
    color: #0c5460;
}

.chat-bubble-success {
    background-color: #d4edda;
    color: #155724;
}

.chat-footer {
    opacity: 0.5;
}

.avatar-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    margin-left: 10px;
}

.chat-image img {
    width: 100%;
    height: 100%;
    display: block;
}

.chat {
    display: flex;
    align-items: flex-start; /* CHECK WORKING !!! todo */
    margin-bottom: 1rem; /* space between messages */
}

.chat-content {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 40px);
}

.chat-header-start {
    align-items: center;
    font-weight: 500;
    margin-left: 10px;
}

.chat-header-end {
    align-items: center;
    font-weight: 500;
    margin-right: 10px;
    text-align: end;
}

.chat-time {
    font-size: 0.75rem;
    opacity: 0.5;
    margin-left: 5px;
}

.chat-end {
    flex-direction: row-reverse;
}

.chat-footer {
    margin-right: 10px;
    text-align: right;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
    margin-left: 20px;
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
}

.dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dot-flashing {
    0% {
        background-color: #9880ff;
    }
    50%, 100% {
        background-color: rgba(152, 128, 255, 0.2);
    }
}


/**
 * ==============================================
 * User feedback
 * ==============================================
 */


.thumbs-container {
    height: 40px; /* Reserve the vertical space; adjust as needed */
    opacity: 0; /* Make it initially invisible */
    transition: opacity 0.3s ease; /* Smooth transition */
    justify-content: flex-end;
    align-items: center;
    padding: 0.2rem;
    color: #cccccc;
    display: flex;
}

.chat-content:hover .thumbs-container {
    opacity: 1; /* Make it visible on hover */
}

.thumbs-button {
    cursor: pointer;
    margin-left: 0.5rem;  /* Add space between buttons */
}

.feedback-input {
    width: 100px;
    margin-right: 0.5rem;  /* Add space between input and buttons */
    padding: 0.2rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.feedback-container {
    min-height: 40px;
    padding: 8px 12px;
    border-radius: 8px;
    align-items: center;
    font-weight: 500;
    margin-right: 10px;
    text-align: end;
}

.feedback-container-0 {
    background-color: red;
}

.feedback-container-1 {
    background-color: #8bfc8b;
 }